import React, { Suspense, lazy } from 'react';
import ReactDOM from 'react-dom';

import FallbackSpinner from 'components/FallbackSpinner';

const LazyApp = lazy(() => import('./App'));

ReactDOM.render(
  <React.StrictMode>
    <Suspense fallback={<FallbackSpinner />}>
      <LazyApp />
    </Suspense>
  </React.StrictMode>,
  document.getElementById('root'),
);
